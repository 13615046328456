// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import { AcCapitalize } from '@utils';

export const AcFormatErrorMessage = (error, list = false) => {
	let msg = false;
	let code =
		error && error.response && error.response.status
			? error.response.status
			: false;

	if (!code || code === 500) {
		return `Er is een onbekende fout opgetreden. Probeer het opnieuw of neem contact op met ${KEYS.SUPPORT_EMAIL_ADDRESS}`;
	}

	if (error.response && error.response.data && error.response.data.errors) {
		msg = [];

		for (let key in error.response.data.errors) {
			if (error.response.data.errors.hasOwnProperty(key)) {
				if (error.response.data.errors[key][0] !== 'p')
					if (!list) {
						let m = error.response.data.errors[key][0];
						m = m.replace(/wachtwoord/gi, 'pincode');
						msg.push(AcCapitalize(m));
					} else if (list) {
						let m = error.response.data.errors[key][0];
						m = m.replace(/wachtwoord/gi, 'pincode');
						msg.push({
							line: AcCapitalize(m),
							key,
						});
					}
			}
		}

		if (!list) msg = msg.join(', ');
	} else if (
		error.response &&
		error.response.data &&
		error.response.data.message
	) {
		msg = error.response.data.message;
		msg = msg.replace(/wachtwoord/gi, 'pincode');
	}

	return msg;
};

export const AcFormatErrorCode = error => {
	return error && error.response && error.response.status
		? error.response.status
		: 'Netwerk Error';
};

export default {
	AcFormatErrorMessage,
	AcFormatErrorCode,
};
